import React from "react"
import { Link } from "gatsby"
import styled, { css, keyframes } from "styled-components"
import MediaQuery from "react-responsive"
import { StaticImage } from "gatsby-plugin-image"
import PageTransition from "gatsby-plugin-page-transitions"
import { Sling as Hamburger } from "hamburger-react"
import CookieConsent from "react-cookie-consent"

import StyledDiv from "./styledDiv"
import Button from "./button"
import Footer from "./footer"
import Menu from "./menu"
import { rhythm } from "../utils/typography"
import * as variables from "../utils/variables"
import "../css/typography.css"

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      animate: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`
    const contactPath = `${__PATH_PREFIX__}/contact/`
    const workPath = `${__PATH_PREFIX__}/work/`
    let header

    const CookieComponent = (
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        overlay="True"
        style={{
          fontFamily: "Actor"
        }}
        aria-label="Accept cookies"
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
  )

    if (location.pathname === rootPath) {
      header = (
        <HeaderDiv>
          <MediaQuery minWidth={1224}>
            <TitleBig
              animate={this.state.animate ? 'true' : 'false'}
            >
              <Link
                style={{
                  boxShadow: `none`,
                  textDecoration: `none`,
                  color: variables.COLOR_blue,
                }}
                to={`/`}
              >
                {title}
              </Link>
            </TitleBig>
          </MediaQuery>
          <MediaQuery maxWidth={1223}>
            <TitleSmall
              animate={this.state.animate ? 'true' : 'false'}
            >
              <Link
                style={{
                  boxShadow: `none`,
                  textDecoration: `none`,
                  color: variables.COLOR_blue,
                }}
                to={`/`}
              >
                {title}
              </Link>
            </TitleSmall>
          </MediaQuery>
        </HeaderDiv>
      )
    } else {
      header = (
        <span>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: variables.COLOR_blue,
            }}
            to={`/`}
          >
            <StaticImage
              src="../icon/livio-icon.png"
              alt="Livio icon"
              layout="fixed"
              width={100}
              height={100}
            />
          </Link>
          <h2
            style={{
              marginTop: 0,
              color: variables.COLOR_blue,
              fontFamily: `FF Bau Bold`,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: variables.COLOR_blue,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h2>
        </span>
      )
    }

    if (location.pathname === rootPath) {
      return (
        <PageTransition>
          <Wrapper>
            <MediaQuery minWidth={1224}>
              <div
                style={{
                  width: `100%`,
                  height: `100%`,
                  position: `absolute`,
                  backgroundColor: `black`,
                  zIndex: -1,
                }}
              >
              </div>
              <div
                style={{
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  maxWidth: rhythm(36),
                  padding: `0 0`,
                  minHeight: `100vh`,
                  display: `flex`,
                  flexDirection: `column`,

                }}
              >
                <div
                  style={{
                    zIndex: 1,
                  }}
                >
                  <header>{header}</header>
                  <StyledDiv />
                </div>
                <main
                  style={{
                    flexGrow: 1,
                    zIndex: 0,
                  }}
                >
                  {children}
                </main>
                <Footer zIndex={1}>
                  <span style={{
                    display: `flex`,
                    justifyContent: `space-between`
                  }}>
                    <Link to="/blog/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Blog Page">Blog</Button>
                    </Link>
                    <Link to="/work/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Work Page">Work</Button>
                    </Link>
                    <Link to="/contact/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Contact Page">Contact</Button>
                    </Link>
                  </span>
                </Footer>
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={1223}>
              <div
                data-sal="fade"
                data-sal-duration="250"
                style={{
                  height: `100vh`,
                  display: `block`,
                  overflowY: `scroll`,
                  scrollSnapType: `y mandatory`,
                  zIndex: -1,
                }}
                >
                <div
                  style={{
                    marginLeft: `auto`,
                    marginRight: `auto`,
                    padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                    height: `100vh`,
                    /* mobile viewport bug fix */
                    minHeight: `-webkit-fill-available`,
                    scrollSnapAlign: `center`,
                  }}
                >
                  <Menu 
                    ref={el => (this.childMenu = el)}
                    parentFunction={() => this.closeMenu()}
                  />
                  <header
                    style={{
                      display: `flex`,
                      justifyContent: `space-around`,
                      alignItems: `center`,
                    }}
                  >
                    {header}
                    <HamburgerButton
                      onClick={() => this.toggleMenu()}
                      ref={input => this.inputElement = input}
                      aria-label="Toggle Hamburger Menu"
                      >
                      <Hamburger
                        size={30}
                        duration={0.5}
                        color={variables.COLOR_slate}
                        toggled={this.state.isOpen}
                        toggle={this.toggleMenu}
                      />
                    </HamburgerButton>
                  </header>
                  <StyledDiv />
                  <div
                    style={{
                      zIndex: 1,
                    }}
                  >
                    {children}
                  </div>
                </div>
                <div
                  style={{
                    marginTop: `auto`,
                    marginLeft: `auto`,
                    marginRight: `auto`,
                    padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                    height: `100vh`,
                    /* mobile viewport bug fix */
                    minHeight: `-webkit-fill-available`,
                    scrollSnapAlign: `center`,
                    display: `flex`,
                    flexDirection: `column`,
                    backgroundColor: `${variables.COLOR_slate}`
                  }}
                >
                  <div style={{ 
                      padding: `1.5rem`,
                      zIndex: `1`,
                    }}>
                    <span role="img" aria-label="waving hand"
                      style={{ 
                        fontSize: `50px`,
                        lineHeight: `3.5rem`,
                      }}>👋</span>
                    <p 
                      style={{
                        fontSize: `20px`
                      }}
                    >Ich bin ein Fotograf aus Luzern, Schweiz</p>
                    <p
                      style={{
                        fontSize: `20px`
                      }}
                    ><Link to="/contact/" style={{ boxShadow: "none" }}>Hier</Link> können Sie mit mir Kontakt aufnehmen.</p>
                  </div>
                  <Footer marginTop="auto">
                    <span
                      style={{
                        display: `flex`,
                        justifyContent: `space-between`,
                        alignItems: `flex-end`,
                        }}>
                      <Link to="/blog/" style={{ boxShadow: "none" }}>
                        <Button marginTop="35px" aria-label="Link to Blog Page">Blog</Button>
                      </Link>
                      <Link to="/work/" style={{ boxShadow: "none" }}>
                        <Button marginTop="35px" aria-label="Link to Work Page">Work</Button>
                      </Link>
                      <Link to="/contact/" style={{ boxShadow: "none" }}>
                        <Button marginTop="35px" aria-label="Link to Contact Page">Contact</Button>
                      </Link>
                    </span>
                  </Footer>
                </div>
              </div>
            </MediaQuery>
            {CookieComponent}
          </Wrapper>
        </PageTransition>
      )
    }
    
    else if (location.pathname === blogPath || location.pathname.includes("/blog/")) {
      return (
        <PageTransition>
          <Wrapper
            style={{
              backgroundImage: `url(data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycKICB4bWxuczp4bGluaz0naHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluaycKICB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJz4KCiAgICA8ZmlsdGVyIGlkPSduJyB4PScwJyB5PScwJz4KICAgICAgPGZlVHVyYnVsZW5jZSBiYXNlRnJlcXVlbmN5PScwLjAwNScgcmVzdWx0PSdub2lzZScgbnVtT2N0YXZlcz0nMScgc3RpdGNoPSdzdGl0Y2gnIHNlZWQ9JzI1Jy8+CiAgICAgIDxmZURpZmZ1c2VMaWdodGluZyBpbj0nbm9pc2UnIGxpZ2h0aW5nLWNvbG9yPScjRkVGRUZFJyBzdXJmYWNlU2NhbGU9JzEwJyBkaWZmdXNlQ29uc3RhbnQ9JzEuNSc+CiAgICAgICAgPGZlRGlzdGFudExpZ2h0IGF6aW11dGg9JzI3MCcgZWxldmF0aW9uPScxNTAnIC8+CiAgICAgIDwvZmVEaWZmdXNlTGlnaHRpbmc+CiAgICA8L2ZpbHRlcj4KCiAgICA8cmVjdCB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJyBmaWxsPScjRkVGRUZFJy8+CiAgICA8cmVjdCB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJyBmaWx0ZXI9InVybCgjbikiIG9wYWNpdHk9JzAuMTUnLz4KPC9zdmc+)`
            }}
          >
            <div
              style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                minHeight: `100vh`,
                display: `flex`,
                flexDirection: `column`,
                maxWidth: rhythm(24),
                padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                backgroundColor: `${variables.COLOR_white}`,
              }}
            >
              <header>{header}</header>
              <StyledDiv />
              <main
                style={{flexGrow: 1}}
              >
                {children}
              </main>
              <Footer>
                <span style={{ display: "flex", justifyContent: "space-between"}}>
                  <Link to="/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Home Page">Home</Button>
                  </Link>
                  <Link to="/work/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Work Page">Work</Button>
                  </Link>
                  <Link to="/contact/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Contact Page">Contact</Button>
                  </Link>
                </span>
              </Footer>
            </div>
            {CookieComponent}
          </Wrapper>
        </PageTransition>
      )
    }
    
    else if (location.pathname === contactPath) {
      return (
        <PageTransition>
          <Wrapper
            style={{
              backgroundImage: `url(data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycKICB4bWxuczp4bGluaz0naHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluaycKICB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJz4KCiAgICA8ZmlsdGVyIGlkPSduJyB4PScwJyB5PScwJz4KICAgICAgPGZlVHVyYnVsZW5jZSBiYXNlRnJlcXVlbmN5PScwLjAwNScgcmVzdWx0PSdub2lzZScgbnVtT2N0YXZlcz0nMScgc3RpdGNoPSdzdGl0Y2gnIHNlZWQ9JzI1Jy8+CiAgICAgIDxmZURpZmZ1c2VMaWdodGluZyBpbj0nbm9pc2UnIGxpZ2h0aW5nLWNvbG9yPScjRkVGRUZFJyBzdXJmYWNlU2NhbGU9JzEwJyBkaWZmdXNlQ29uc3RhbnQ9JzEuNSc+CiAgICAgICAgPGZlRGlzdGFudExpZ2h0IGF6aW11dGg9JzI3MCcgZWxldmF0aW9uPScxNTAnIC8+CiAgICAgIDwvZmVEaWZmdXNlTGlnaHRpbmc+CiAgICA8L2ZpbHRlcj4KCiAgICA8cmVjdCB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJyBmaWxsPScjRkVGRUZFJy8+CiAgICA8cmVjdCB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJyBmaWx0ZXI9InVybCgjbikiIG9wYWNpdHk9JzAuMTUnLz4KPC9zdmc+)`
            }}
          >
            <div
              style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                minHeight: `100vh`,
                display: `flex`,
                flexDirection: `column`,
                maxWidth: rhythm(24),
                padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                backgroundColor: `${variables.COLOR_white}`
              }}
            >
              <header>{header}</header>
              <StyledDiv />
              <main
                style={{flexGrow: 1}}
              >
                {children}
              </main>
              <Footer>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Link to="/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Home Page">Home</Button>
                  </Link>
                  <Link to="/blog/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Blog Page">Blog</Button>
                  </Link>
                  <Link to="/work/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Work Page">Work</Button>
                  </Link>
                </span>
              </Footer>
            </div>
            {CookieComponent}
          </Wrapper>
        </PageTransition>
      )
    }

    else if (location.pathname === workPath || location.pathname.includes("/work/")) {
      return (
        <PageTransition>
          <Wrapper
            style={{
              backgroundImage: `url(data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycKICB4bWxuczp4bGluaz0naHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluaycKICB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJz4KCiAgICA8ZmlsdGVyIGlkPSduJyB4PScwJyB5PScwJz4KICAgICAgPGZlVHVyYnVsZW5jZSBiYXNlRnJlcXVlbmN5PScwLjAwNScgcmVzdWx0PSdub2lzZScgbnVtT2N0YXZlcz0nMScgc3RpdGNoPSdzdGl0Y2gnIHNlZWQ9JzI1Jy8+CiAgICAgIDxmZURpZmZ1c2VMaWdodGluZyBpbj0nbm9pc2UnIGxpZ2h0aW5nLWNvbG9yPScjRkVGRUZFJyBzdXJmYWNlU2NhbGU9JzEwJyBkaWZmdXNlQ29uc3RhbnQ9JzEuNSc+CiAgICAgICAgPGZlRGlzdGFudExpZ2h0IGF6aW11dGg9JzI3MCcgZWxldmF0aW9uPScxNTAnIC8+CiAgICAgIDwvZmVEaWZmdXNlTGlnaHRpbmc+CiAgICA8L2ZpbHRlcj4KCiAgICA8cmVjdCB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJyBmaWxsPScjRkVGRUZFJy8+CiAgICA8cmVjdCB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJyBmaWx0ZXI9InVybCgjbikiIG9wYWNpdHk9JzAuMTUnLz4KPC9zdmc+)`
            }}
          >
            <div
              style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                minHeight: `100vh`,
                display: `flex`,
                flexDirection: `column`,
                maxWidth: rhythm(24),
                padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                backgroundColor: `${variables.COLOR_white}`
              }}
            >
              <header>{header}</header>
              <StyledDiv />
              <main
                style={{flexGrow: 1}}
              >
                {children}
              </main>
              <Footer>
                <span style={{ display: "flex", justifyContent: "space-between"}}>
                  <Link to="/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Home Page">Home</Button>
                  </Link>
                  <Link to="/blog/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Blog Page">Blog</Button>
                  </Link>
                  <Link to="/contact/" style={{ boxShadow: "none" }}>
                      <Button marginTop="35px" aria-label="Link to Contact Page">Contact</Button>
                  </Link>
                </span>
              </Footer>
            </div>
            {CookieComponent}
          </Wrapper>
        </PageTransition>
      )
    }
    
    else {
      return (
        <PageTransition>
          <Wrapper
            style={{
              backgroundImage: `url(data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycKICB4bWxuczp4bGluaz0naHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluaycKICB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJz4KCiAgICA8ZmlsdGVyIGlkPSduJyB4PScwJyB5PScwJz4KICAgICAgPGZlVHVyYnVsZW5jZSBiYXNlRnJlcXVlbmN5PScwLjAwNScgcmVzdWx0PSdub2lzZScgbnVtT2N0YXZlcz0nMScgc3RpdGNoPSdzdGl0Y2gnIHNlZWQ9JzI1Jy8+CiAgICAgIDxmZURpZmZ1c2VMaWdodGluZyBpbj0nbm9pc2UnIGxpZ2h0aW5nLWNvbG9yPScjRkVGRUZFJyBzdXJmYWNlU2NhbGU9JzEwJyBkaWZmdXNlQ29uc3RhbnQ9JzEuNSc+CiAgICAgICAgPGZlRGlzdGFudExpZ2h0IGF6aW11dGg9JzI3MCcgZWxldmF0aW9uPScxNTAnIC8+CiAgICAgIDwvZmVEaWZmdXNlTGlnaHRpbmc+CiAgICA8L2ZpbHRlcj4KCiAgICA8cmVjdCB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJyBmaWxsPScjRkVGRUZFJy8+CiAgICA8cmVjdCB3aWR0aD0nMjU2MCcgaGVpZ2h0PScyNTYwJyBmaWx0ZXI9InVybCgjbikiIG9wYWNpdHk9JzAuMTUnLz4KPC9zdmc+)`
            }}
          >
            <div
              style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                minHeight: `100vh`,
                display: `flex`,
                flexDirection: `column`,
                maxWidth: rhythm(24),
                padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                backgroundColor: `${variables.COLOR_white}`,
              }}
            >
              <header>{header}</header>
              <StyledDiv />
              <main
                style={{flexGrow: 1}}
              >
                {children}
              </main>
              <Footer />
            </div>
            {CookieComponent}
          </Wrapper>
        </PageTransition>
      )
    }
  }

  // animate on every /home page load
  componentDidMount() {
    this.setState({ animate: true })
  }

  toggleMenu() {
    this.childMenu.open()
    this.setState({isOpen: true})
    console.log("toggle menu from layout called")
  }

  closeMenu() {
    this.setState({isOpen: false})
    console.log("toggle menu from layout called")
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const fadeIn = keyframes`
  from {
    filter: blur(50px);
    color: rgba(255,255,255,0);
    transform: scaleX(2);
  } to {
    filter: blur(0);
    color: ${variables.COLOR_blue};
    transform: scaleX(1);
  }
`

const TitleSmall = styled.h1`
  text-transform: uppercase;
  font-size: 32px;
  line-height: 3rem;
  margin: 0;
  font-family: FF Bau Super;
  color: ${variables.COLOR_blue};
  animation: ${(props) => props.animate ? css`${fadeIn} ease-in 0.25s` : ''};
  text-shadow:
    2px 2px 0 ${variables.COLOR_slate},
    -1px -1px 0 ${variables.COLOR_slate},  
    1px -1px 0 ${variables.COLOR_slate},
    -1px 1px 0 ${variables.COLOR_slate},
    1px 1px 0 ${variables.COLOR_slate};
`

const TitleBig = styled.h1`
  text-transform: uppercase;
  font-size: 40px;
  line-height: 4rem;
  margin: 1.5rem 0;
  font-family: FF Bau Super;
  color: ${variables.COLOR_blue};
  animation: ${(props) => props.animate ? css`${fadeIn} ease-in 0.25s` : ''};
  text-shadow:
    3px 3px 0 ${variables.COLOR_slate},
    -1px -1px 0 ${variables.COLOR_slate},  
    1px -1px 0 ${variables.COLOR_slate},
    -1px 1px 0 ${variables.COLOR_slate},
    1px 1px 0 ${variables.COLOR_slate};
`

const HeaderDiv = styled.div`
  animation: ${(props) => props.animate ? css`${fadeIn} ease-in 0.25s` : ''};
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

const HamburgerButton = styled.button`
  background-color: ${variables.COLOR_blue};
  padding: 4px;
  border-radius: 4px;
  transition: all 0.5s cubic-bezier(0, 0, 0, 1);
  border: 1px ${variables.COLOR_slate};
  box-shadow: 1px 1px ${variables.COLOR_slate};
`

export default Layout