import styled, { keyframes } from "styled-components"
import * as variables from "../utils/variables"

const divFadeIn = keyframes`
    from {
        background-color: rgba(255,255,255,0);
        width: 0
    } to {
        background-color: ${variables.COLOR_yellow};
        width: 100%
    }
`

const StyledDiv = styled.div`
    height: 3px;
    margin: 6px 0;
    animation: ${divFadeIn} ease-in 0.75s forwards;
`



export default StyledDiv