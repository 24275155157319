import React from "react"
import styled from "styled-components"
import * as variables from "../utils/variables"
import "../css/typography.css"

const Button = props => (
  <ButtonWrapper props={props}>{props.children}</ButtonWrapper>
)

const ButtonWrapper = styled.button`
  display: block;
  border: none;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "FF Bau Bold";
  transition-duration: 500ms;
  transition-property: box-shadow;

  @media (max-width: 1223px) {
    padding: 4px 5px 2px 9px;
    letter-spacing: 4px;
    font-size: 13px;
  }
  @media (min-width: 1224px) {
    padding: 7px 8px 7px 16px;
    letter-spacing: 6px;
    font-size: 15px;
  }

  background: ${props => props.props.background || variables.COLOR_blue};
  color: ${props => props.props.color || "rgb(255, 255, 255)"};
  font-size: ${props => props.props.fontSize || "15px"};
  font-weight: ${props => props.props.fontWeight || "600"};
  border-radius: ${props => props.props.radius || "6px"};
  margin-top: ${props => props.props.marginTop};
  margin-bottom: ${props => props.props.marginBottom};

  &:hover, &:active {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.25);
  }
`

export default Button