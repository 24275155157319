import React, { useImperativeHandle, useRef } from "react"

import { Link } from "gatsby"

import styled from "styled-components"
import * as variables from "../utils/variables"

import styles from "./menu.module.scss"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  render() {
    return (
      <div
        role="button"
        tabIndex="0"
        className={`${styles.menu} ${this.state.open ? `${styles.open}` : ""}`}
        onClick={() => this.close()}
        onKeyDown={() => this.close()}
      >
        <Link to="/"><StyledH2>Home</StyledH2></Link>
        <Link to="/blog/"><StyledH2>Blog</StyledH2></Link>
        <Link to="/work/"><StyledH2>Work</StyledH2></Link>
        <Link to="/contact/"><StyledH2>Contact</StyledH2></Link>
      </div>
    )
  }

  close() {
    this.setState({ open: false })
    console.log("open menu from menu called")
    this.props.parentFunction()
  }

  open() {
    this.setState({ open: true })
    console.log("open menu from menu called")
  }
}

const StyledH2 = styled.h2`
  display: block;
  border: none;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "FF Bau Bold";
  color: ${variables.COLOR_blue};
  transition-duration: 0.1s;
  transition-property: color;

  &:hover, &:active {
    color: rgba(8, 13, 24, 0.1);
  }
`

export default React.forwardRef((props, ref) => {
  const menuRef = useRef()

  useImperativeHandle(ref, () => ({
    open() {
      menuRef.current.open()
    },
  }))

  return <Menu ref={menuRef} {...props} />
})