import React from "react"
import StyledDiv from "./styledDiv"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import * as variables from "../utils/variables"

const Footer = props => {
  return (
    <StaticQuery
      query={footerQuery}
      render={data => {
        const author = data.site.siteMetadata.author
        return (
          <Wrapper props={props}>
            {props.children}
            <StyledDiv />
            <StyledFooter>
              <Text>© {new Date().getFullYear()} by {author}</Text>
            </StyledFooter>
          </Wrapper>
        )
      }}
    />
  )
}

const StyledFooter = styled.footer`
  margin: 8px auto 0 auto;
  display: flex;
  justify-content: center;
  padding: 0;
`

const Wrapper = styled.div`
  margin-top: ${props => props.props.marginTop || "auto"};
  z-index: ${props => props.zIndex || 0};
`

const Text = styled.p`
  background-color: ${variables.COLOR_slate_semitransparent};
  padding: 4px;
  border-radius: 4px;
`

const footerQuery = graphql`
  query {
    site {
      siteMetadata {
        author
      }
    }
  }
`

export default Footer